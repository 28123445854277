// src/store/modules/payments.js

// import client from "../../utils/client"
import axios from "@/utils/client";

const state = { payments: null };

const mutations = {};

export const actions = {
    createMerchantPaymentSession({ commit }, params) {

        console.log('createMerchantPaymentSession commit', commit);

        return axios
            .put(`/payments/createMerchantPaymentSession`, params)
            .then(response => {
                console.log('createMerchantPaymentSession response', response);
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    createMerchantCustomer({ commit }, params) {

        console.log('createCustomer commit', commit);

        return axios
            .put(`/payments/createCustomer`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    createPaymentCard({ commit }, params) {

        console.log('createPaymentCard commit', commit);

        return axios
            .post(`/payments/createPaymentCard`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    updatePaymentCard({ commit }, params, cardId) {
        console.log('updatePaymentCard commit', commit);
        return axios
            .post(`/payments/updatePaymentCard/${cardId}`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    }
};

const getters = {
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
