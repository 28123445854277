<template>
    <div>
        <div class="flex flex-wrap items-center gap-2 2xl:gap-y-0">
            <span @click="updateStatusFilter(status)" v-for="status in statuses" :key="status.name" class="badge" :class="statusStyling(status.name)">
                <input 
                    :checked="isChecked(status.name)" 
                    :value="status.name" 
                    type="checkbox" 
                    :class="checkboxStyling(status.name)"

                >
                {{ status.name }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "GigStatusCheckbox",
    data() {
        return {
            checkedStatuses: [],
            statusesToSend: []
        }
    },
    props:{
        statuses: {
            type: Array,
            required: true
        },
        venueFilter:{
			required: true
		}
    },
    mounted() {
        this.checkedStatuses = JSON.parse(JSON.stringify(this.statuses.map(status => status.name)));
        this.statusesToSend = JSON.parse(JSON.stringify(this.statuses.map(status => status.type)));
    },
    methods:
    {
        updateStatusFilter($status)
        {

            let index = this.checkedStatuses.indexOf($status.name);

            if (this.venueFilter !== 'all'){
                if (this.isChecked($status.name) === true) {
                    this.checkedStatuses.splice(index, 1);
                    this.statusesToSend.splice(index, 1);
                } else {
                    this.checkedStatuses.push($status.name);
                    this.statusesToSend.push($status.type);
                }
            } else {
                if (this.isChecked($status.name) === false) {
                    this.checkedStatuses = [];
                    this.statusesToSend = [];
                    this.checkedStatuses.push($status.name);
                    this.statusesToSend.push($status.type);
                }
            }

            this.$emit('statusFiltersUpdated', this.statusesToSend);

        },
        allSelected() {
            this.checkedStatuses = [];
            this.statusesToSend = [];
            this.checkedStatuses.push("Pending response");
            this.statusesToSend.push("NEEDS_RESPONSE");
            this.$emit('statusFiltersUpdated', this.statusesToSend);
        },
        venueSelected() {
            if (this.checkedStatuses.length !== this.statuses.length) {
                this.checkedStatuses = [];
                this.statusesToSend = [];
                this.statuses.map(status => { 
                    this.checkedStatuses.push(status.name) 
                    this.statusesToSend.push(status.type)
                });
                this.$emit('statusFiltersUpdated', this.statusesToSend);
            }
        },
        isChecked(statusName) {
            return this.checkedStatuses.includes(statusName);
        },
        statusStyling($status_name)
        {

            const baseClassNotChecked = 'bg-gray-100 text-gray-400 cursor-pointer'
            
            switch ($status_name) {
                case 'Draft':
                    return this.isChecked($status_name) ? 'mb-3 bg-indigo-100 text-indigo-800 hover:bg-indigo-200 cursor-pointer' : `${baseClassNotChecked} hover:bg-indigo-100 hover:text-indigo-800`;
                case 'Cancelled':
                    return this.isChecked($status_name) ? 'mb-3 bg-gray-100 text-gray-800 hover:bg-gray-200 cursor-pointer' : `${baseClassNotChecked} hover:bg-gray-100 hover:text-gray-800`;
                case 'Pending response':
                    return this.isChecked($status_name) ? 'mb-3 bg-red-600 text-white hover:bg-red-200 cursor-pointer' : `${baseClassNotChecked} hover:bg-red-600 hover:text-white`;
                case 'Searching for Artists':
                    return this.isChecked($status_name) ? 'mb-3 bg-yellow-100 text-yellow-800 hover:bg-yellow-200 cursor-pointer' : `${baseClassNotChecked} hover:bg-yellow-100 hover:text-yellow-800`;
                case 'Confirmed':
                    return this.isChecked($status_name) ? 'mb-3 bg-green-500 text-white hover:bg-green-200 hover:text-green-600 cursor-pointer' : `${baseClassNotChecked} hover:bg-green-500 hover:text-white`;
                case 'Needs sign-off':
                    return this.isChecked($status_name) ? 'mb-3 bg-[#b45309] text-gray-100 cursor-pointer' : `${baseClassNotChecked} hover:bg-[#b45309] hover:text-gray-100`;
                case 'Signed-off':
                    return this.isChecked($status_name) ? 'mb-3 bg-green-100 text-green-800 hover:bg-green-200 cursor-pointer' : `${baseClassNotChecked} hover:bg-green-100 hover:text-green-800`;
                case 'Artist cancelled':
                  return this.isChecked($status_name) ? 'mb-3 bg-[#FA7900] text-white hover:bg-[rgba(250,121,0,0.7819502801120448)] cursor-pointer' : `${baseClassNotChecked} hover:bg-[rgba(250,121,0,0.7819502801120448)] hover:text-white`;
            }
        },
        checkboxStyling($status_name)
        {
            const baseClass = 'mr-1 w-[14px] h-[14px] bg-gray-100 rounded focus:outline-none hover:cursor-pointer';

            switch ($status_name) {
                case 'Draft':
                    return this.isChecked($status_name) ? `${baseClass} text-indigo-500` : `${baseClass} text-gray-200`;
                case 'Cancelled':
                    return this.isChecked($status_name) ? `${baseClass} text-gray-400` : `${baseClass} text-gray-200`;
                case 'Pending response':
                    return this.isChecked($status_name) ? `${baseClass} text-red-400` : `${baseClass} text-gray-200`;
                case 'Searching for Artists':
                    return this.isChecked($status_name) ? `${baseClass} text-yellow-700` : `${baseClass} text-gray-200`;
                case 'Confirmed':
                    return this.isChecked($status_name) ? `${baseClass} text-green-700` : `${baseClass} text-gray-200`;
                case 'Needs sign-off':
                    return this.isChecked($status_name) ? `${baseClass} text-[#d56d1c]` : `${baseClass} text-gray-200`;
                case 'Signed-off':
                    return this.isChecked($status_name) ? `${baseClass} text-green-600` : `${baseClass} text-gray-200`;
              case 'Artist cancelled':
                return this.isChecked($status_name) ? `${baseClass} text-[rgba(110,53,1,1)]` : `${baseClass} text-gray-200`;
            }
        }
    }
}
</script>