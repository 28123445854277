<template>
    <div class="w-[350px] xl:w-[500px] mt-16">
       
        <div v-show="step == 1" class="flex items-end max-w-screen-lg mx-auto">
            <div class="w-full">
                <div class="flex items-center w-full">
                    <div
                        class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1.5 flex items-center justify-center rounded-full">
                        <span class="w-2 h-2 bg-gp_pink-default-400 rounded-full"></span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">2</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">3</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">4</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <!-- <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div> -->
            <!-- <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">6</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div> -->
            <div>
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="step == 2" class="flex items-end max-w-screen-lg mx-auto">
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                    <div
                        class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1.5 flex items-center justify-center rounded-full">
                        <span class="w-2 h-2 bg-gp_pink-default-400 rounded-full"></span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">3</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">4</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <!-- <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">6</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div> -->
            <div>
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="step == 3" class="flex items-end max-w-screen-lg mx-auto">
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                    <div
                        class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1.5 flex items-center justify-center rounded-full">
                        <span class="w-2 h-2 bg-gp_pink-default-400 rounded-full"></span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">4</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <!-- <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">6</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div> -->
            <div>
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="step == 4" class="flex items-end max-w-screen-lg mx-auto">
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                    <div
                        class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1.5 flex items-center justify-center rounded-full">
                        <span class="w-2 h-2 bg-gp_pink-default-400 rounded-full"></span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <!-- <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">6</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div> -->
            <div>
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">5</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div v-show="step == 5" class="flex items-end max-w-screen-lg mx-auto">
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                    <div
                        class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1.5 flex items-center justify-center rounded-full">
                        <span class="w-2 h-2 bg-gp_pink-default-400 rounded-full"></span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">6</span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div>
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">7</span>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div v-show="step == 6" class="flex items-end max-w-screen-lg mx-auto">
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                    <div
                        class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1.5 flex items-center justify-center rounded-full">
                        <span class="w-2 h-2 bg-gp_pink-default-400 rounded-full"></span>
                    </div>
                    <div class="w-full h-1 bg-gray-300"></div>
                </div>
            </div>
            <div>
                <div class="flex items-center">
                    <div class="w-6 h-6 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                        <span class="text-xs text-gray-300 font-bold">7</span>
                    </div>
                </div>
            </div>
        </div> -->

        <div v-show="step == 5" class="flex items-end max-w-screen-lg mx-auto">
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <!-- <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center w-full">
                <div
                    class="w-6 h-6 shrink-0 mx-[-1px] border-2 border-gp_pink-default-400 p-1 flex items-center justify-center rounded-full">

                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full text-gp_pink-default-400" viewBox="0 0 24 24">
                    <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000" 
                        fill="currentColor"
                    />
                    </svg>
                </div>
                <div class="w-full h-1 bg-gp_pink-default-400"></div>
                </div>
            </div> -->
            <div>
                <div class="flex items-center">
                <div class="w-6 h-6 border-gp_pink-default-400 shrink-0 mx-[-1px] border-2 p-1.5 flex items-center justify-center rounded-full">
                    <span class="w-2 h-2 bg-gp_pink-default-400 rounded-full"></span>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['step']
    }
</script>
