<template>

	<div>
		<InlineNotification Heading="Adding a new venue" class="ml-4" v-show="!this.user.is_opus">
			<template slot="body">
				<p v-show="this.isVenueTrial && !this.isVenueTrial.is_trial">Click the “Add new venue” button and fill in the details to register your venue. Repeat this process if you have more than one site. All your venues will be managed under this account.</p>
				<div v-show="this.isVenueTrial && this.isVenueTrial.is_trial" class="space-y-3">
					<p>“Click the ‘Add new venue’ button and fill in the details to register additional venues.</p>
					<p>All your venues will be managed under this account. This feature is available when you post your first gig after your trial, or when you start a subscription.</p>
				</div>
			</template>
			<template slot="cta">
				<div @click="venueAddCheck()" class="gp_cta cursor-pointer">Add new Venue</div>
			</template>
		</InlineNotification>

		<PageDetails>

			<!-- <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">Your venues</h1>  -->

			<template v-if="isLoading">
				<div class="p-8 flex justify-center items-start">
					<PageSpinner 
						tailwind-diameter="8"
						tailwind-border="4"
						tailwind-color="pink-500"
					/>
				</div>
			</template>

			<template v-else-if="!isLoading && !hasData">
				<NoDataMsgWrapper>There are no venues found for this account</NoDataMsgWrapper>
			</template>

			<template v-else>
				<TableWrapper>
					<template #header-rows>
						<tr>
							<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6"></th>
							<th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6"></th>
							<th scope="col" class="hidden px-3 py-3.5 lg:table-cell">
								<span class="sr-only">Upcoming gigs</span>
							</th>
							<th scope="col" class="relative w-8 px-3 py-3.5 ">
								<span class="sr-only">Edit</span>
							</th>
						</tr>
					</template>
					<template #body-rows v-if="venues">
						<tr v-for="venue in venues" :key="venue.id">
							<td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
								<div class="flex items-center gap-3">
									<img
										class="shadow self-start w-8 h-8 object-contain"
										:src="venueIcon(venue)"
										:alt="venue.name + ' logo'" 
									/>
									<span>{{ venue.name }}</span>
								</div>
								
								<dl class="font-normal lg:hidden">
									<dt class="sr-only sm:hidden">Upcoming gigs</dt>
									<dd class="mt-2 truncate text-gray-500 sm:hidden">
										<span>{{ getGigs(venue) }}</span>
									</dd>
								</dl>

								<div class="flex space-x-3 items-center lg:hidden mt-4">
									<div>
										<ClockIcon stroke="white" class="w-6 h-6 bg-gp_pink-default-400 rounded-full p-1"/>
									</div>
									<div class="text-gray-500 text-sm font-light">
										<p>720 credits remaining</p>
										<p>Renewing 11 months, 30 days</p>
									</div>
								</div>
								<div class="px-5 py-1 text-xs text-white bg-gray-600 rounded-md flex items-center space-x-3 uppercase font-bold lg:hidden mt-3 w-44">
									<CreditCardIcon stroke="white" class="w-5 h-6"/>
									<span>Expires Soon</span>
								</div>
							</td>
							<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
								<div class="col col-span-2 flex gap-12 items-center">
									<div class="flex space-x-3 items-center">
										<div>
											<ClockIcon stroke="white" class="w-6 h-6 bg-gp_pink-default-400 rounded-full p-1"/>
										</div>
										<div class="text-gray-500">
											<p>720 credits remaining</p>
											<p>Renewing 11 months, 30 days</p>
										</div>
									</div>
									<div class="px-5 py-1 text-xs text-white bg-gray-600 rounded-md flex items-center space-x-3 uppercase font-bold">
										<CreditCardIcon stroke="white" class="w-5 h-6"/>
										<span>Expires Soon</span>
									</div>
								</div>
							</td>
							<td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
								<span>{{ getGigs(venue) }}</span>
							</td>
							<td class="w-8 px-3 py-4 text-right text-sm font-medium sm:pr-6">
								<a 
									@click.prevent="onEditVenue(venue.id)" 
									class="text-indigo-600 hover:text-indigo-900"
								>
									<span class="sm:hidden"><PencilAltIcon /></span>
									<span class="hidden sm:block">
										Edit<span class="sr-only">, {{ venue.name }}</span>
									</span>
								</a>
							</td>
						</tr>
					</template>
				</TableWrapper>
			</template>

		</PageDetails>
	</div>
</template>

<script>
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import TableWrapper from '../../components/ui/table/TableWrapper.vue';
import PageSpinner from '../../components/ui/spinner/PageSpinner.vue';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import { PencilAltIcon, ClockIcon, CreditCardIcon } from "@vue-hero-icons/outline"
import pluralize from  '@/utils/pluralize.js'
import InlineNotification from '../../components/notifications/inline.vue'
import {venueMethods} from "@/state/helpers"; 

export default {
  components: {
		PageDetails,
		TableWrapper,
		PageSpinner,
		NoDataMsgWrapper,
		PencilAltIcon, ClockIcon, CreditCardIcon,
		InlineNotification
  },
  data() {
    return {
			venues:null,
			isVenueTrial: {},
			isLoading:false,
    };
  },
  computed: {
 
        user() {
            return this.$store.getters['user/userData']
        },
		hasData(){
			if(this.venues){
				if(this.venues.length){
					return true;
				}
			}
			return false;
		},
		getGigs(){
			return (venue) => pluralize(venue.gig_count, 'upcoming gig');
		},
		venueIcon(){
			return (venue) => {
				if(venue.icon && venue.icon.url){
					return venue.icon.url
				}else if(venue.icon && venue.icon.preview){
					return venue.icon.preview
				}else{
					return require('@/assets/images/placeholder.svg')
				}
			};
			
		},
  },
  methods: {
    ...venueMethods,
		onEditVenue(venueId){
			this.$router.push({ name: "VenuesDetails", params: { venueId: venueId } });
		},
		venueAddCheck() {
			
			if (this.isVenueTrial && this.isVenueTrial.is_trial) {
				this.$notify({text: 'This feature is available when you post your first gig after your trial, or when you start a subscription.',  duration: 5000});
				return;
			}

			this.$router.push("/venues/add");
		}
  },
	mounted(){

		this.isLoading = true;

		this.getVenuesList().then(response => {
		this.venues = response
		}).catch((error) => {
		console.log('Error in getVenuesList', error)
		}).finally(() => {
			this.isLoading = false;
			if (this.venues && this.venues.length === 1 && this.user && this.user.is_opus) {
				this.onEditVenue(this.venues[0].id);
			}
		});

		this.isVenueTrial = JSON.parse(localStorage.getItem('venue_trial'));

	}
};
</script>
