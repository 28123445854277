<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div :class="modalStyling">
                    <div v-show="step == 1"  class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase" id="modal-title">Subscription options</h3>
                                <p class="uppercase text-sm mt-2 text-gray-100 font-medium">Venue name here</p> 
                            </div>

                            <div class="mt-6">
                                <div class="grid grid-cols-2">
                                    <div>
                                        <p class="text-md mt-2 text-gray-100">Approximate Gig's booked a week</p> 
                                    </div>
                                    <div class="flex items-center justify-center mt-2">
                                        <div class="w-1/2 py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700" data-hs-input-number="">
                                            <div class="flex justify-between items-center gap-x-5">
                                                <div class="grow">
                                                    <input 
                                                        v-model="cred_qty"
                                                        class="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0" 
                                                        style="appearance: textfield; -moz-appearance: textfield; -webkit-appearance: textfield; position: relative;"
                                                        type="number" 
                                                        aria-roledescription="Number field" 
                                                        value="1" 
                                                        data-hs-input-number-input=""
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid grid-cols-4 mt-12">
                                    <div class="space-y-8 col-span-3">
                                        <p class="text-md mt-2 text-gray-200 font-light">Ammount to pay today</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Monthly charge</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Credits today</p> 
                                    </div>
                                    <div class="space-y-8 place-items-end">
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ amountToday }} + VAT</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ monthlyCharge }} + VAT</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">{{ creditsToday }}</p> 
                                    </div>
                                </div>
                            </div>

                            <div class="w-full pr-10 mt-10">
                                <div @click="upgradeNow()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-xl px-6 text-white">
                                    <span class="font-semibold w-11/12 uppercase">Upgrade now</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                            </div>

                            <div class="space-y-2 mt-10">
                                <p @click="toggleModal()" class="underline text-xs text-gray-200 font-bold cursor-pointer">&lt; Back to PAYG options</p>
                            </div>

                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="h-auto w-full flex justify-center items-center">
                            <div class="justify-center">
                                <div class="flex justify-end mr-10">
                                    <p class="block font-bold text-gp_pink-default-400">Save £ a year!</p>
                                </div>
                                <div class="bg-gray-400/30 px-6 py-4 rounded-xl mt-4">
                                    <div class="flex justify-between">
                                        <p class="text-md mt-2 text-gray-100 font-light">Top up discount!</p>
                                        <p class="text-md mt-2 text-gray-100 font-light">£9.23 per gig</p>
                                    </div>

                                    <p class="text-sm mt-6 text-gray-100 font-light">You’ll receive all your tokens for the year ahead today to allow you to book ahead of time.</p>
                                    <p class="text-sm mt-6 text-gray-100 font-light">Concerned you’ll need more?  You can still top-up on the spot at a discounted rate when you run low on credits.</p>
                                </div>

                                <div class="space-y-2 mt-4 flex justify-end">
                                    <p class="underline text-xs text-gray-200 font-bold cursor-pointer">Need more options?  Get in touch with our sales team</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 2"  class="h-auto w-full">
                        <div>
                            <h3 class="block font-bold text-gp_pink-default-400 uppercase" id="modal-title">Payment complete</h3>
                            <p class="uppercase text-sm mt-2 text-gray-100 font-medium">Venue name here</p> 
                        </div>
                        <div class="mt-6">    
                            <div class="space-y-3">
                                <p class="text-gray-100 text-sm">Payment reference 9234819</p>
                                <p class="text-gray-100 text-sm">A receipt invoice has been sent to accounts@myvenue.com</p>
                            </div>
                        </div>
                        <div class="w-full pr-10 mt-10">
                            <div @click="continueBooking()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-xl px-6 text-white">
                                <span class="font-semibold w-11/12">Continue booking</span>
                                <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                            </div>
                        </div>
                    </div>

                </div>
            </transition>        
        </div>
    </div>
</template>

<script>
import { ArrowNarrowRightIcon } from "@vue-hero-icons/outline"
// import { TToggle } from 'vue-tailwind/dist/components'

export default
{
    name: "SubscriptionUpgrade", 
    data() {
        return {
            modal_open: false,
            cred_qty: 1,
            step: 1,
            cards: ['Card one', 'Card two']
        }
    },
    components: {
        ArrowNarrowRightIcon,
        // TToggle
    },
    computed: {
        modalStyling() {
            return this.step == 1 ? 'inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6' : 'inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6'
        },
        amountToday() {
            return (this.cred_qty * 4) * 10
        },
        monthlyCharge() {
            return (this.cred_qty * 4) * 10
        },
        creditsToday() {
            return (this.cred_qty * 4) * 12
        }
    },
    methods: {
        upgradeNow() {
            this.step = 2;
        },
        toggleModal() {
            this.modal_open = !this.modal_open;
        }
    }
}

</script>