<template>  
    <div v-if="bid" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
               
            </div>
    
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">        
                <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                           <div>
        <div class="mx-auto flex flex-row justify-between items-center ">
          
          <h3 class=" font-bold leading-6text-gray-900" id="modal-title">
           Do you want to book {{bid.artist.name}}?  
          </h3> 
        </div>
        <div class="text-left sm:mt-5" >
            <div class="text-sm"> 
                <p>Fee: {{bid.fee}}</p>
                <p class="my-6">
							Confirming will book {{bid.artist.name}} onto this Gig, and the booking is complete
                </p>
                <div class="my-6 font-bold">
                    <p v-if="specialism">The artist will be notified they will be booked under the specialism {{specialism}}.</p>
                    <p v-else class="my-6">
                        {{bid.artist.name}} will be sent a notification.
                    </p>
                </div>
                <div class="flex justify-between">
                    <a class="gp_cta bg-gp_navy-default-500" @click="closeModal">Cancel</a>
                    <GPButton 
                        v-if="!message"
                        @click.native="acceptBid"
                        :disabled="btnDisabled"
                        :loading="btnDisabled"
                    >Confirm</GPButton>
                    <div v-else>{{message}}</div>
                </div>
            </div>
        </div>
        
      </div>
                </div>
            </transition>
        </div>
    </div>
</template>
 
<script>
import GPButton from '../../components/ui/buttons/Button.vue'
import { apiComputed } from '@/state/helpers'; 



export default {
    name: "BidAccept",
    components:{
        GPButton
    },
    data(){
        return {
            btn: false,
        }
    },
    computed:{
        ...apiComputed,
        btnDisabled(){
            return this.btn;
        }
    },
    props:{
        bid: Object,
        specialism: String,
        message: String,
    },
    methods: {
        closeModal() {
            this.$emit('closeModal',
                false
            )
        },
        acceptBid()
        {
            this.btn = true;
            this.$emit('acceptBid');
        }

    }
}
</script>

