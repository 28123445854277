<template>
    <div v-show="openModal" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-6 px-6 md:px-12 justify-items-center text-center">
                            <h3 class="block font-bold text-gp_pink-default-400 uppercase mt-6" id="modal-title">Welcome to your free trial</h3> 
                            <p class="text-gray-100 font-bold">
                                Your free trial lasts 7 days.  During this time you get access to the full GigPig Experience, including Artist Directory, Favourite Lists and Gig Templates.
                            </p>

                            <p class="text-gray-100 font-bold">
                                As soon as you add one paid credit, you unlock these features forever.
                            </p>

                            <p class="text-gray-100 font-bold">
                                We’ve given you a free token worth £10 to start!  This will let you book your first gig.
                            </p>

                            <div @click="closeModal()" class="bg-gp_pink-default-500 hover:bg-gp_pink-default-400 cursor-pointer my-6 py-2 px-6 rounded">
                                <span class="text-white">Let's Begin!</span>
                            </div>

                        </div>
                    </div>

                   
                </div>
            </transition>        
        </div>
    </div>
</template>

<script>


export default
{
    name: "VenueTrialWelcome", 
    data() {
        return {
            openModal: false,
        };
    },
    created() {
        const storedValue = sessionStorage.getItem('openWelcomeModal');
        if (storedValue === 'true') {
            this.openModal = true;
        } else {
            this.openModal = false;
        }
    },
    methods: {
        closeModal() {
            sessionStorage.setItem('openWelcomeModal', "false");
            this.openModal = false;
            window.location.reload();
        }
    }
}

</script>