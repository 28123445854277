<template>
  <div class="relative h-full w-screen bg-[#0D1C43] flex flex-col align-center items-center">
    <div ref="background"
      class="absolute z-1 inset-0 bg-cover bg-center bg-fixed opacity-10"
      :style="{ backgroundImage: `url(${background})` }" 
    />
    <div class="pt-24 z-40 flex flex-col items-center justify-center w-auto h-auto">
      <img id="desktop_logo" class="h-28 w-auto mb-4" :src="logo">
      <span v-show="form.step != 8" class="text-white text-md font-light mt-4">Welcome to GigPig. Let's get started.</span>
      <span v-show="!isLoaded" class="text-gp_pink-default-400 text-xl font-bold py-44">Loading...</span>

      <Stepper v-show="form.step != 6 && isLoaded" :step="form.step" class="fade-in mt-12"/>

      <div>
        <!-- Step 1 -->
        <div v-show="form.step == 1 && isLoaded" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-[#0D1C43] bg-opacity-60 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-400 text-xl font-black">Venue Name</span>
          <span class="text-white text-md font-normal">Add your venue name</span>
          <div class="pt-4">
            <input type="text" 
                  v-model="form.name"
                  id="venue_name" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  placeholder="Enter your venue name" 
                  required 
            />
          </div>
          <div class="w-full flex justify-between pt-8">
              <div class="flex invisible items-center space-x-2">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepOne()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>
      
        <!-- Step 2 -->
        <div v-show="form.step == 2  && isLoaded" class="fade-in w-[500px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-[#0D1C43] bg-opacity-60 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-400 text-xl font-black">Venue Branding</span>
          <span class="text-white text-md font-normal">Upload your logo or venue image</span>
          <div class="pt-6 cursor-pointer text-gp_pink-default-400">
              <div class="input-group mt-1 sm:mt-0 sm:col-span-2">
                <profile-picture-upload 
                  ref="picuploader"
                  :image="form.icon" 
                  @onImageChange="profilePictureUploaded" 
                  profile-type="VenueProfilePicture"
                />
              </div>
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepTwoSkip()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <span class="text-sm">Skip</span>
              </div>

              <div @click="stepTwo()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>

        <!-- Step 3 -->
        <div v-show="form.step == 3" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-[#0D1C43] bg-opacity-60 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-400 text-xl font-black">Venue Location</span>
          <span v-show="!locationName" class="text-white text-md font-normal">Where is your venue located</span>
          <span v-show="locationName" class="text-white text-md font-normal">Is this the correct location?</span>

          <div v-show="!locationName" @click="openLocationModal()" class="pt-6 cursor-pointer text-gp_pink-default-400 hover:text-gp_pink-default-500">
              <PlusIcon class="h-12 w-12 p-3 border border-dashed rounded-full"/>
          </div>
          <div v-show="locationName" class="pt-6 flex items-center space-x-2">
            <strong class="text-xl text-white">{{ locationName }}</strong>
            <XCircleIcon @click="removeLocation()" class="cursor-pointer text-gp_pink-default-500 h-4 w-4" />
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepThree()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>

        <!-- Step 4 -->
        <div v-show="form.step == 4" class="fade-in w-[420px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-[#0D1C43] bg-opacity-60 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-400 text-xl font-black">Venue Details</span>
          <span class="text-white text-md font-normal">Venue address and contact details</span>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-400">Line 1</span>
            <input type="text" 
                  v-model="form.address.address_line_1"
                  id="line_1" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-400">Line 2 (Optional)</span>
            <input type="text" 
                  v-model="form.address.address_line_2"
                  id="line_2" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-400">Town / city</span>
            <input type="text" 
                  v-model="form.address.town"
                  id="town" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-400">County</span>
            <input type="text" 
                  v-model="form.address.county"
                  id="county" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-400">Postcode</span>
            <input type="text" 
                  v-model="form.address.post_code"
                  id="post_code" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-400">Email</span>
            <input type="text" 
                  v-model="form.email"
                  id="email" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-400">Telephone</span>
            <input type="text" 
                  v-model="form.telephone"
                  id="telephone" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepFour()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>

        <!-- Step 5 -->
        <div v-show="form.step == 5" class="fade-in mt-24 w-[420px] flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-[#0D1C43] bg-opacity-60 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-400 text-xl font-black">Venue Details</span>
          <span class="text-white text-md font-normal">Venue billing details</span>

          <div class="pt-4 space-y-1 text-sm font-semibold w-full">
            <span class="text-gp_pink-default-400">Currency</span>
            <TRichSelect
                id="currencies"
                name="currencies"
                v-model="form.currency"
                :options="currencies"
                @change="pickCurrency"
                value-attribute="id"
                text-attribute="display_name"
                class="w-full block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepFive()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>

        <!-- Step 6 -->
        <div v-show="form.step == 6" class="fade-in mt-24 flex flex-col items-center justify-center space-y-4 w-full h-full p-16 bg-[#0D1C43] bg-opacity-60 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-400 text-xl font-black">🐽 Welcome to GigPig! 🐽</span>
          <span class="text-white text-md font-normal">Your venue is onboarded</span>
          <span class="text-white text-md font-normal">You can now start booking gigs</span>

          <div class="pt-6 w-full">
            <GPButton @click.native="toAddGig()"
                        color="pink"
                        class="w-full">Get Started</GPButton> 
          </div>

          <div class="relative flex pt-9 items-center w-full">
              <div class="flex-grow border-t border-gray-400"></div>
              <div class="flex-grow border-t border-gray-400"></div>
          </div>

          <div class="w-96 pt-4 text-center">
            <div class="py-6">
              <span class="text-sm text-center block text-gray-400">
                Want to enhance the look of your venue before you start booking? Click the link below and we’ll guide you through
                the process of adding more details to your venue, including a gallery, an ‘about’ section and social media tags.
              </span>
              <p @click="toVenueEdit()" class="underline my-6 text-gray-400 hover:text-gp_pink-default-400 font-semibold text-sm cursor-pointer inline-block">Add more venue details</p>
            </div>

            <div>
              <span class="text-sm text-center block text-gray-400">
                Want to know more about the cool features you have at your disposal before you start booking? 
                Click the link below to take the GigPig guided tour.
              </span>
              <p @click="toDashboard()" class="underline my-6 text-gray-400 hover:text-gp_pink-default-400 font-semibold text-sm cursor-pointer inline-block">Take the GigPig tour!</p>
            </div>
          </div> 
        </div>
      </div>

      <div class="mt-24 pb-64 flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-400">
          <UserIcon @click="$auth.logout()" class="w-5 h-5 cursor-pointer" />
          <span @click="$auth.logout()" class="cursor-pointer">Logout</span>
      </div>


    </div>

    <VenueLocationModal 
      ref="locationModal" 
      v-on:saveLocation="storeLocation($event)" 
      v-on:updateVenueDetails="updateVenueDetails($event)" 
      v-on:updateCountry="pickCountry($event)"
    />
   </div>




</template>

<script>
import { UserIcon, ArrowNarrowRightIcon, ArrowNarrowLeftIcon, PlusIcon, XCircleIcon } from "@vue-hero-icons/outline"
import NProgress from 'nprogress';
import ProfilePictureUpload from '../../components/uploads/profile-picture-upload.vue'
import VenueLocationModal from '../../components/modal/VenueLocation.vue';
import { TRichSelect } from 'vue-tailwind/dist/components'
import { DEFAULT_CURRENCY_ID } from "@/constants/contants";
import client from "../../utils/client"
import GPButton from '../../components/ui/buttons/Button.vue';
import Stepper from '../../components/ui/stepper/VenueOnboardStepper.vue';
import JSConfetti from 'js-confetti'

export default {
  name: "VenueOnboard",
  components: {
    UserIcon, ArrowNarrowRightIcon, ArrowNarrowLeftIcon, PlusIcon, XCircleIcon,
    ProfilePictureUpload,
    VenueLocationModal,
    TRichSelect,
    GPButton,
    Stepper,
  },
  data() {
    return {
      background: require('@/assets/images/concert.jpg'),
      logo: require("@/assets/images/gigpig_logo_white_book_gigs_get_gigs_tight.svg"),
      currencies: [],
      townPreview: '',
      countyPreview: '',
      form: {
        id: null,
        name: '',
        icon: null,
        step: 1,
        address: {
          address_line_1: '',
          address_line_2: '',
          town: '',
          county: '',
          post_code: '',
        },
        email: '',
        telephone: '',
        location: {},
        country_id: null,
        currency: DEFAULT_CURRENCY_ID,
        cardId: null
      },
      same_as_address: false,
      addressCopy: {
        address_line_1: '',
        address_line_2: '',
        town: '',
        county: '',
        post_code: '',
      },
      billingAddressCopy: {
        address_line_1: '',
        address_line_2: '',
        town: '',
        county: '',
        post_code: '',
        company_name: '',
      },
      formCopy: null,
      isLoaded: false,
      toggleCopyAddress: false,
    };
  },
  watch: {
    toggleCopyAddress(val) {
      this.same_as_address = val;
    },
    'form.icon.uploading'(newValue) {
      if (newValue === false) {
        this.stepTwo();
      }
    },
  },
	computed:{
    locationName() {
      return this.form.country_id ? this.form.location.preview : ''
    },
    currencyIsoCode() {
      return this.currencies.find(currency => currency.id === this.form.currency).iso_code;
    },
    userData(){
			return this.$store.getters['user/userData']
		},
	},
  async mounted() {

  NProgress.start();

  client.get('currencies').then(response => (this.currencies = response.data.data))

  await client
          .get('venues-onboarding')
          .then((response) => {

              if(response.data.data) {
                this.form = response.data.data;
              }

              this.dataChecks();
          })
          .finally(() => {
              this.formCopy = JSON.parse(JSON.stringify(this.form))
              this.isLoaded = true;
              NProgress.done();
          })

  },
  methods: {
    openLocationModal() {
      this.$refs.locationModal.isDarkMode = true;
      this.$refs.locationModal.toggleModal();
    },
    goBack() {
      if (this.form.step > 1) {
        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = this.form.step - 1;
      }
    },
    dataChecks() {

      if(this.form.email == null) {
        this.form.email = this.userData.email;
      }

      if(this.form.address == null) {
        this.form.address = JSON.parse(JSON.stringify(this.addressCopy));
      }

      if(this.form.billing_address == null) {
        this.form.billing_address = JSON.parse(JSON.stringify(this.billingAddressCopy));
      }

      if(this.form.currency == null) {
        this.form.currency = DEFAULT_CURRENCY_ID;
      }

      if (this.form.billing_address.address_line_1 !== '' && JSON.stringify(this.form.address) == JSON.stringify(this.form.billing_address)) {
        this.toggleCopyAddress = true;
      }

      if (this.townPreview !== '') {
        this.form.address.town = this.townPreview;
      }

      if (this.countyPreview !== '') {
        this.form.address.county = this.countyPreview;
      }

      if (this.form.step == 6) {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
        const end_date  = futureDate.toISOString().split('T')[0];


        let venue_trial = {
          is_trial: true,
          credits_remaining: 1,
          end_date: end_date,
          venue_id: this.form.id,
          venue_name: this.form.name,
        }

        localStorage.setItem('venue_trial', JSON.stringify(venue_trial));

      }

    },
    async updateVenue(params = null) {

      NProgress.start();

      const requestObject = params ? params : this.form;

      await client
              .put('venues-onboarding/' + this.form.id, requestObject)
              .then((response) => {
                  
                  if(response.data.data) {
                    this.form = response.data.data;
                  }

                  this.dataChecks();

                  this.$refs.background.scrollIntoView({behavior: 'smooth'});
              })
              .finally(() => {
                this.formCopy = JSON.parse(JSON.stringify(this.form))
                NProgress.done();
              })
    },
    async stepOne() {

      if (!this.form.name) {
        this.$notify("Please input a venue name.");
        return;
      } 

      if (this.form.name != this.formCopy.name) {

        if (!this.form.id) {

          NProgress.start();

          await client
                  .post('venues-onboarding', this.form)
                  .then((response) => {

                      if(response.data.data) {
                        this.form = response.data.data;
                      }

                      this.dataChecks();
                  })
                  .finally(() => {
                    this.formCopy = JSON.parse(JSON.stringify(this.form))
                    NProgress.done();
                  })

        } else {
          await this.updateVenue();
        }

      } else {
        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = 2;
      }

    },
    stepTwoSkip() {
      this.form.icon != null ? this.stepTwo() : this.$refs.picuploader.uploadDefault()
    },
    async stepTwo() {

      if (this.form.icon == null) {
        this.$notify("Please upload a venue image / logo.");
        return;
      }

      if (this.form.icon.uploading == true) {
        this.$notify("Image is still uploading. Please try again momentarily.");
        return;
      }

      if (JSON.stringify(this.form.icon) != JSON.stringify(this.formCopy.icon)) {
        await this.updateVenue();
      } else {
        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = 3;
      }

    },
    async stepThree() {

      if (this.form.location == null || this.form.country_id == null) {
        this.$notify("Please choose a venue location.");
        return;
      }

      if (JSON.stringify(this.form.location) != JSON.stringify(this.formCopy.location)) {
        await this.updateVenue();
      } else {
        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = 4;
      }

    },
    async stepFour() {

      if (!this.form.address.address_line_1  || !this.form.address.town || !this.form.address.county || 
          !this.form.address.post_code || !this.form.telephone || !this.form.email) 
      {
        this.$notify("Please provide all requested details.");
        return;
      }

      if (!this.form.email.includes('@')) {
        this.$notify("Please provide a valid email address.");
        return;
      }

      if ((JSON.stringify(this.form.address) !== JSON.stringify(this.formCopy.address)) || this.form.telephone != this.formCopy.telephone || this.form.email != this.formCopy.email) {

        this.form.address.country_id = this.form.country_id;
        await this.updateVenue();

      } else {

        if (this.form.address != null && this.form.billing_address != null && JSON.stringify(this.form.address) == JSON.stringify(this.form.billing_address)) {
          this.toggleCopyAddress = true;
        }

        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = 5;
      }

    },
    async stepFive() {

      if (!this.form.currency) {
        this.$notify("Please provide all requested details.");
        return;
      }
      
      await this.updateVenue();
      this.$refs.background.scrollIntoView({behavior: 'smooth'});

      this.form.step = 6;
      this.loadConfetti();
    },
    toVenueEdit() {
      this.$router.push({ name: "VenuesDetails", params: { venueId: this.form.id } });
      sessionStorage.setItem('openWelcomeModal', "true");
    },
    toAddGig() {
      this.$router.push('/gigs/add');
      sessionStorage.setItem('openWelcomeModal', "true");
    },
    toDashboard() {
      this.$router.push('/dashboard');
      sessionStorage.setItem('openWelcomeModal', "true");
    },
    profilePictureUploaded(image) {
			this.form.icon = image;
		},
    storeLocation($location){
      this.form.location = $location;
      this.stepThree();
    },
    removeLocation(){
      this.form.location = {};
      this.form.country_id = null;
    },
    pickCountry(value){
        this.form.country_id = value;
    },
    pickCurrency(value) {
      this.form.currency = value
    },
    updateVenueDetails(value) {
      this.townPreview = value.place ? value.place : '';
      this.countyPreview = value.area ? value.area : '';
    },
    loadConfetti() {
      const jsConfetti = new JSConfetti()
      jsConfetti.addConfetti({
        confettiRadius: 6,
        confettiNumber: 500,
      })

      setTimeout(function() {
        jsConfetti.addConfetti({
          confettiRadius: 6,
          confettiNumber: 500,
        });
      }, 2000);
    }
  }
};
</script>


<style>
@keyframes fadeIn {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.fade-in {
  animation: fadeIn 2s ease-in-out;
}
</style>
