<template>
    <div v-if="show" transition="fadein" class="fixed z-50 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 relative">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut">
                <div v-click-outside="closeModal" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div
                        @click="closeModal" 
                        class="absolute top-[18px] right-[26px] cursor-pointer text-lg line-height-0"
                        id="modalClose"
                        ><span>
                            <svg style="height: 26px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                        </span>
                    </div>
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-1 w-full ">
                            <div class="justify-between flex">

                              <slot name="header"><h3 class="text-xl font-bold leading-6 text-gray-900" id="modal-title">DEFAULT HEADER</h3></slot>
                            </div>
                            <slot name="header2"></slot>

                            <slot>DEFAULT CONTENT</slot>
                        </div>
                        <slot name="buttons">DEFAULT BUTTONS</slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {type: Boolean, default: false},
        hasLoaded: {type: Boolean, default: false}
    },
    components: {

    },
    methods: {
        closeModal() {
          this.$emit('closeModal')
      }
    },
    watch: {
      show(value) {
        this.$nextTick(() => {
          this.$emit('modalRendered');
        });

        document.body.style.overflow = value === true ? 'hidden' : 'auto';
      }
    },
    emits: ['closeModal', 'modalRendered'],
}
</script>