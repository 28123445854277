<template>
    <div v-if="this.isVenueTrial.is_trial && this.isPastTrialDate" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div>
                        <div class="mx-auto grid grid-flow-row gap-6 px-6 md:px-12 justify-items-center text-center">
                            <h3 class="block font-bold text-gp_pink-default-400 uppercase mt-6" id="modal-title">Your free trial has expired</h3> 
                            <p class="text-gray-100 font-bold">
                                Click here to top-up and enable the full GigPig experience forever
                            </p>

                            <div class="flex flex-wrap md:gap-8 justify-center items-center">
                                <div @click="$router.push('/gigs/add')" class="bg-gray-100 hover:bg-gray-200 cursor-pointer my-2 md:my-6 py-2 px-6 rounded-lg flex space-x-2">
                                    <TicketIcon stroke="black"/>
                                    <span class="text-black">Book a gig</span>
                                </div>
                                <div @click="openTopUpModal()" class="bg-gp_pink-default-500 hover:bg-gp_pink-default-400 cursor-pointer my-2 md:my-6 py-2 px-6 rounded-lg flex space-x-2">
                                    <DatabaseIcon stroke="white"/>
                                    <span class="text-white">Add credit now</span>
                                </div>
                            </div>

                        </div>
                    </div>

                   
                </div> 
            </transition>        
        </div>
        <TopUpOptions ref="topupmodal" />
    </div>
</template>

<script>
import { DatabaseIcon, TicketIcon } from "@vue-hero-icons/outline"
import TopUpOptions from '../../components/modal/venues/subscriptions/TopUpOptions.vue'

export default
{
    name: "VenueTrialExpired", 
    data() {
        return {
            isVenueTrial: {},
			isPastTrialDate: false,
        }
    },
    components: {
        DatabaseIcon, TicketIcon,
        TopUpOptions
    },
    mounted() {
        this.isVenueTrial = JSON.parse(localStorage.getItem('venue_trial'));

		// this.isVenueTrial.end_date = "2024-09-05";

		if (this.isVenueTrial && this.isVenueTrial.is_trial) {
			this.checkTrialDate();
		}
    },
    methods: {
		checkTrialDate() {
			const currentDate = new Date(); 
			const targetDate = new Date(this.isVenueTrial.end_date);
			this.isPastTrialDate = currentDate > targetDate;
		},
        openTopUpModal() {
            this.$refs.topupmodal.toggleModal();
        },
	}
}

</script>