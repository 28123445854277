<template>
  <div>
    <div class="flex items-center">
      <div class="mr-8">
        <div v-if="image === null" class="rounded w-32 h-32 overflow-hidden bg-gray-100">
          <svg
            class="mt-auto h-100 w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </div>
        <div
          v-if="image && (image.preview !== null || image.url !== null)"
          class="rounded relative w-32 h-32 overflow-hidden bg-gray-100"
        >
          <div
            v-if="image && image.uploading === true"
            class="absolute flex justify-center items-center w-full h-full"
          >
            <PageSpinner />
          </div>
          <img
            class="h-full w-full object-cover"
            :src="image && (image.preview || image.url)"
          />
        </div>
      </div>

      <file-input class="profile-picture" :uppy="uppy" />

      <div class="ml-3">
        <span v-if="image && image.name !== null">{{ image && image.name }}</span>
        <span v-else>No file chosen</span>
      </div>
    </div>

    <div v-if="restrictionErrors.length > 0" class="mt-4">
      <ul>
        <li
          v-for="(restrictionError, index) in restrictionErrors"
          :key="index"
          class="text-red-500 text-sm"
        >
          {{ restrictionError }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AWSS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import ms from "ms";
import FileInput from "@uppy/vue/src/file-input";
import { getUploadParameters, confirmUpload } from "../../utils/uppy";
import PageSpinner from "../ui/spinner/PageSpinner.vue";

export default {
  components: {
    FileInput,
    PageSpinner,
  },
  beforeDestroy() {
    this.uppy.close({ reason: "unmount" });
  },
  data() {
    return {
      restrictionErrors: [],
    };
  },
  props: {
    image: {
      type: Object,
    },
		profileType:{
			type: String,
			default:"ArtistProfilePicture"
		}
  },
  computed: {
    uppy() {
      const uppy = new Uppy({
        id: this.profileType,
        autoProceed: true,
        restrictions: {
          maxNumberOfFiles: 1,
          maxFileSize: 4194304,
          allowedFileTypes: [".jpg", ".jpeg", ".png"],
        },
        locale: {
          strings: {
            chooseFiles: "Change",
          },
        },
      })
        .use(ThumbnailGenerator, {
          thumbnailWidth: 200,
        })
        .use(AWSS3, {
          limit: 2,
          timeout: ms("1 minute"),
          getUploadParameters,
        })
        .on("restriction-failed", (file, error) => {
          this.restrictionErrors = [error.message];
        })
        .on("thumbnail:generated", (file, preview) => {
          const { id, name, progress } = file;
          const { uploadComplete } = progress;

          this.restrictionErrors = [];

          this.$emit("onImageChange", {
            id: id,
            name: name,
            preview: preview,
            uploading: !uploadComplete,
            uppyId: id,
          });
        })
        .on("upload-success", (file) => {
          confirmUpload(file).then(() => {
            const { meta } = file;

            if (this.image && this.image.uppyId) {
              this.uppy.removeFile(this.image.uppyId);
            }

            this.$emit("onImageChange", { ...this.image, uploading: false, id: meta.id });
          });
        });

      return uppy;
    },
  },
  methods: {
   async uploadDefault() {
      const response = await fetch(require('@/assets/images/bg_concert.png'));
      const blob = await response.blob();

      this.uppy.addFile({
        name: 'bg_concert.png',
        type: blob.type, 
        data: blob, 
        meta: {
          relativePath: 'bg_concert.png',
        },
      });
    }
  }
};
</script>

<style>
.profile-picture .uppy-FileInput-container {
  margin: 0;
}
</style>
