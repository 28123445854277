import VenuesList from '../views/Venues/Index.vue';
import VenuesAdd from '../views/Venues/Add.vue';
import VenueOnboard from '../views/Venues/Onboard.vue';
import VenuesDetails from '../views/Venues/ID.vue';
import VenuesManageRoles from '../views/Venues/ManageRoles';
import VenuesManageTeams from '../views/Venues/ManageTeams.vue';
import VenuesManageSubscription from '../views/Venues/ManageSubscription.vue';
import VenuesManageCards from '../views/Venues/ManageCards.vue';
import VenuesAcceptInvite from '../views/Venues/AcceptInvite.vue'

export default (store) => [
	{
    path: "/venues",
    name: "VenuesList",
    meta: { authRequired: true },
    component: VenuesList
  },
  {
    path: "/venues/add",
    name: "VenuesAdd",
    meta: { authRequired: true, title: 'Add a new venue' },
    component: VenuesAdd
  },
  {
    path: "/venues/onboard",
    name: "VenueOnboard",
    meta: { authRequired: true, title: 'Add a new venue' },
    component: VenueOnboard
  },
	{
	path: "/venues/:venueId",
	name: "VenuesDetails",
	meta: { authRequired: true },
	props: true,
	beforeEnter: (to, from, next) => {
			if(store.getters['venue/venues']){
				let venue = store.getters['venue/venues'].find(venue => {
					return venue.id === Number(to.params.venueId)
				})
				if (venue) {
					store.dispatch('venue/storeVenue', venue).then(() => {
						next()
					})
				} else {
					next()
				}
			}else{
				next()
			}
		
	},
	component: VenuesDetails
  },
	{
		path: "/venues/:venueId/manage-roles",
		name: "VenuesManageRoles",
		component: VenuesManageRoles,
		meta: { authRequired: true },
		props:true,
		beforeEnter: (to, from, next) => {
			if(store.getters['venue/venues']){
				let venue = store.getters['venue/venues'].find(venue => {
					return venue.id === Number(to.params.venueId)
				})
				if (venue) {
					store.dispatch('venue/storeVenue', venue).then(() => {
						next()
					})
				} else {
					next()
				}
			}else{
				next()
			}
		}
	},
	{
		path: "/venues/:venueId/manage-team",
		name: "VenuesManageTeam",
		component: VenuesManageTeams,
		meta: { authRequired: true },
		props:true,
		beforeEnter: (to, from, next) => {
			if(store.getters['venue/venues']){
				let venue = store.getters['venue/venues'].find(venue => {
					return venue.id === Number(to.params.venueId)
				})
				if (venue) {
					store.dispatch('venue/storeVenue', venue).then(() => {
						next()
					})
				} else {
					next()
				}
			}else{
				next()
			}
		}
	},
	{
		path: '/venues/:venueId/manage-subscription',
		name: 'VenuesManageSubscription',
		component: VenuesManageSubscription,
		meta: { authRequired: true },
		props: true,
		beforeEnter: (to, from, next) => {
			if(store.getters['venue/venues']){
				let venue = store.getters['venue/venues'].find(venue => {
					return venue.id === Number(to.params.venueId)
				})
				if (venue) {
					store.dispatch('venue/storeVenue', venue).then(() => {
						next()
					})
				} else {
					next()
				}
			}else{
				next()
			}
		}
	},
	{
		path: '/venues/:venueId/manage-cards',
		name: 'VenuesManageCards',
		component: VenuesManageCards,
		meta: { authRequired: true },
		props: true,
		beforeEnter: (to, from, next) => {
			if(store.getters['venue/venues']){
				let venue = store.getters['venue/venues'].find(venue => {
					return venue.id === Number(to.params.venueId)
				})
				if (venue) {
					store.dispatch('venue/storeVenue', venue).then(() => {
						next()
					})
				} else {
					next()
				}
			}else{
				next()
			}
		}
	},
	{
		path: "/venues/:venueId/accept-invite/:inviteId",
		component: VenuesAcceptInvite,
		props:true,
		meta: { authRequired: true },
	},
]
