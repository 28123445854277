<template>
  <PageDetails>
    <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
      Edit Venue {{ isLoading ? "-" : "-" }}
      <span v-if="venue">{{venue.name}}</span>
    </h1>
    <div class="lg:max-w-5xl">
      <VenueTabs :venue-id="venueId" current-route="manage-cards">
        <template v-slot:right-cta>
          <div class="bg-gp_pink-default-500 hover:bg-gp_pink-default-400 flex items-center px-3 py-1 md:py-2 rounded-lg text-white space-x-2 cursor-pointer">
            <CreditCardIcon />
            <span class="text-sm md:text-md">Change venue card</span>
          </div>
        </template>
      </VenueTabs>

      <template v-if="isLoading">
        <div class="p-8 flex justify-center items-start">
          <PageSpinner
            tailwind-diameter="8"
            tailwind-border="4"
            tailwind-color="pink-500"
          />
        </div>
      </template>

      <template v-else>
          <div class="mt-16 max-w-7xl">
                <div class="grid grid-cols-2 lg:grid-cols-6 bg-gray-100 px-4 py-2 rounded-t-lg font-light text-sm border">
                  <div class="hidden lg:block">Card Nickname</div>
                  <div class="hidden lg:block">Expiry</div>
                  <div class="hidden lg:block">Additional information</div>
                </div>

                <div class="grid grid-cols-2 lg:grid-cols-6 bg-none px-4 py-4 rounded-b-lg font-light text-sm border items-center">
                  <div>
                    <span class="font-bold block lg:hidden">Card Nickname:</span>
                    <p class="">Paul Mulligans</p>
                  </div>
                  <div>
                    <span class="font-bold block lg:hidden">Expiry:</span>
                    <p class="">11/28</p>
                  </div>
                  <div>
                    <p class="font-bold">**** 3423</p>
                  </div>
                  <div class="space-y-3 mt-4 lg:mt-0">
                    <div class="flex items-center space-x-2">
                      <span class="font-bold">Added</span>
                      <span class="">2024-01-12</span>
                    </div>
                    <div>
                      <span class="font-bold">Added by</span>
                      <p class="">testemail@testemail.com</p>
                    </div>
                  </div>
                  <div class="ml-16">
                    <div class="px-5 py-1 text-xs text-white bg-gray-600 rounded-md flex items-center space-x-3 uppercase font-bold mt-3 w-44">
                      <CreditCardIcon stroke="white" class="w-5 h-6"/>
                      <span>Expires Soon</span>
                    </div>
                  </div>
                </div>
          </div>
      </template>
    </div>

    <NotificationSmall ref="notification" />
  </PageDetails>
</template>

<script>
import PageDetails from "@/components/ui/wrappers/PageDetails.vue";
import VenueTabs from "../../components/venues/VenueTabs.vue";
import { CreditCardIcon } from "@vue-hero-icons/outline"

export default {
  components: {
    VenueTabs,
    PageDetails,
    CreditCardIcon
  },
  props: ["venueId"],
  data() {
    return {
      
    };
  },
  computed: {
    venue() {
      return this.$store.getters['venue/currentVenue']
    },
  },
  methods: {
    
  },
  mounted() {
    
  }
};
</script>
