<template>
	<GigPageWrapper
		:gig-id="String($attrs.id)" 
		current-route="choose-artists"
		title="Choose artists"
		@loaded="onCalendarWrapperLoaded"
	>
        <InlineNotification DismissId="ChooseArtists" v-show="this.item.payment_terms && !isGigCancelled" Heading="Find your artists" class="mt-4">
            <template slot="body">
            <p>Choose from your favourites list, search for a specific artist, or let GigPig search for all the available artists in your area.</p>
            </template> 
        </InlineNotification> 

         <InlineNotification v-show="isGigCancelled" Heading="Access denied">
            <template slot="body">
                <p class="mt-2 text-justify">This feature is reserved for gigs that have not been cancelled. Please navigate to the gig details page using the component below if you wish to see the full detailed breakdown of this cancelled gig.</p>
                <router-link :to="'/gigs/' + $attrs.id" class="flex items-center mt-1 bg-transparent w-48"><ArrowCircleLeftIcon class="mr-2" color="pink"/>Go to gig details page</router-link>
            </template> 
        </InlineNotification>  

        <InlineNotification v-show="(!this.item.payment_terms && !isGigCancelled) && isLoaded" Heading="Payment terms required">
            <template slot="body">
                <p class="mt-2 text-justify">The artist payment terms are required in order to book artists for this gig. Please navigate to the artist instructions page and provide the requested information.</p>
                <router-link :to="'/gigs/' + $attrs.id + '/instructions'" class="flex items-center mt-1 bg-transparent w-64"><ArrowCircleLeftIcon class="mr-2" color="pink"/>Go to artist instructions page</router-link>
            </template> 
        </InlineNotification>


            <div v-show="this.item.payment_terms && !isGigCancelled && this.isLoaded" class="lg:max-w-5xl">

                <form class="form" ref="form" novalidate> 

                <div class="col-span-full py-4">
                    <h2>How do you want to book artists - use artists you already have as favourites, or let GigPig find them for you?</h2>
                </div>

                <div class="sm:col-span-4 grid grid-flow-col items-center">
                    <label for="enabled">
                        Choose artists from favourites lists
                    </label>
                    <t-toggle name="usefaves" id="usefaves"  ref="usefaves" v-model="use_faves" class="justify-self-end" :disabled="!doesGigNeedBooking"/>
                    
                </div>

                <div class="sm:col-span-4 grid grid-flow-col items-center">
                    <label for="enabled">
                        Let GigPig find artists for me 
                    </label>
                    <t-toggle name="usesearch" id="usesearch"  ref="usesearch" v-model="use_search" class="justify-self-end" :disabled="!doesGigNeedBooking" />
                    
                </div>         

				<div class="sm:col-span-4 grid grid-flow-col items-center">
                    <label for="enabled" class="pr-8">
                      Fast-book an artist (This will skip the availability process).
                    </label>
                    <t-toggle name="usehard" id="usehard"  ref="usehard" @click="removeArtists()" v-model="use_hard" class="justify-self-end" :disabled="!doesGigNeedBooking"/>
                </div>

                <div v-show="use_faves || use_hard" class="col-span-full">
                    <SearchByFave 
                        ref="SearchByFave"  
                        :passed_artistdirectory="directory"
                        :passed_tiers="tiers"  
                        :passed_faveslists="faveslist"
                        :defaultFees="item.gig_tiers"
                        :genres="genres" 
                        :select-multiple-artist="canSelectMultipleArtists"
                        :fastBookBtnToggle="use_hard"
                        @fastBook = "applyFastBook($event)"
                        @updatedStaged = "updateStaged($event)"
                        @changesMade="changesMade = true"
                        :is_fetching_directory="is_fetching_directory"
                        :is_fetching_faves="is_fetching_faves"
                        :prefix="prefix"
                        :bids="item.bids"
                        :gig-status="item.status"
                    />
                </div>


                <div v-show="use_search" class="col-span-4">

                  <label class="mb-3">Pick a type of artist you'd like to search for</label>
                  <t-rich-select

                      :close-on-select="true" v-model="search_tiers[0]"
                      :options="tiers"
                      :name="'tiers'"
                      text-attribute="name"
                      value-attribute="id"
                      placeholder="Type" class="w-full"  ref="tiers" @change="tiersChange"
                  ></t-rich-select>

                <SearchByTag
                    class="pb-48"
                    @coversChange="coversChange"
                    @genresChange="genresChange"
                    @moreInfoChange="moreInfoChange"
                    :tagLimitReached="tagLimitReached"
                    :search_tiers="search_tiers"
                    :search_genres="search_genres"
                    :search_covers="search_covers"
                    :search_moreInfo="search_moreInfo"
                    :single_tier="true"
                    :showTiers="false"
                    :tiers="[]"
                    :covers="covers"
                    :genres="genres"
                    :more-info="moreInfo"
                ></SearchByTag>
                        </div>  
                </form>
                
                <div class="action-bar">               

                                        <template v-if="this.item && this.item.published && this.item.bookings.length > 0">
											<span class="text-xs uppercase justify-center h-full text-gp_pink-default-500">{{ getGigBookedLabel }}</span>
										</template>
                     
										<template v-if="this.item && !this.use_hard && !isGigCancelled">
											<GPButton 
												@click.native="showConfirmationModal(true)" 
												:color="getOfferGigBtnColor"
												:disabled="useFavesGigBtnDisabled || useSearchGigBtnDisabled" 
												:loading="isLoading && saveType === 'published'"
												size="lg"
											>{{ getOfferGigBtnLabel }}</GPButton>
										</template>
										<template v-if="isGigCancelled">
											<span class="text-xs uppercase justify-center h-full text-gray-600">This gig has been cancelled</span>
										</template>

                  <GPButton
                      v-if="!isGigCancelled"
                      @click.native="save(false)"
                      :disabled="isLoading || isGigCancelled"
                      :loading="isLoading && saveType === 'unpublished'"
                      size="lg"
                  >Save</GPButton>
                </div>
            </div>
        <NotificationSmall ref="notification" />
        <NoDataMsgWrapper v-if="!this.isLoaded && !isGigCancelled">Loading artists. Please wait.</NoDataMsgWrapper>
        <ModalWrapper 
            ref="paymentModal" 
            :is-open="paymentModalOpen"
        >
            <template #header>
                <div class="text-xl font-semibold">Unable to offer out gig</div>
            </template>

            <template #body>
                <p v-if="paymentModalMessage !== null && hasBillingPermission">{{ paymentModalMessage }}</p>

                <p v-else-if="!hasBillingPermission">
                    This venue has no available subscription. You do not have billing permissions to update this, please ask your venue's billing admin.
                </p>
            </template>

            <template #footer>
                <div class="mb-4 mt-6 px-6 flex">
                    <div class="ml-auto flex">
                        <GPButton @click.native="closePaymentModal" color="gray" class="mr-4">
                            Close
                        </GPButton>
                        <GPButton v-show="hasBillingPermission" @click.native="goToPAYGCheckout" :loading="paymentModalPAYGButtonLoading" color="indigo" class="mr-4">
                            PAYG top-up
                        </GPButton>
                        <GPButton v-show="hasBillingPermission" @click.native="goToManageSubscription" color="pink">
                            Upgrade venue subscription
                        </GPButton>
                    </div>
                </div>
            </template>
        </ModalWrapper>
        <ModalWrapper 
            ref="gigConfirmationModal" 
            :is-open="confirmationModalOpen"
        >
            <template #header>
                <div class="text-xl font-semibold">Booking this artist</div>
            </template>

            <template #body>
                <p class="mb-6">This venue has {{ remainingGigCount }} gigs remaining for {{ monthRemaining }}.</p>

                <p>By booking this gig, you will have {{ postUsageCount }} gig bookings remaining.</p>
            </template>

            <template #footer>
                <div class="mb-4 mt-6 px-6 flex">
                    <div class="ml-auto flex">
                        <GPButton @click.native="closeConfirmationModal" color="gray" class="mr-4">
                            Close
                        </GPButton>
                        <GPButton v-show="hasBillingPermission" @click.native="goToManageSubscription" :loading="paymentModalPAYGButtonLoading" color="indigo" class="mr-4">
                            Upgrade subscription
                        </GPButton>
                        <GPButton @click.native="save(true)" color="pink">
                            Proceed
                        </GPButton>
                    </div>
                </div>
            </template>
        </ModalWrapper>

      <ModalWrapper
          ref="gigPaygConfirmationModal"
          :is-open="paygConfirmationModal"
      >
        <template #header>
          <div class="text-xl font-semibold">Booking this artist</div>
        </template>

        <template #body>
          <p class="mb-6">By confirming that you would like to proceed, the card ending {{ last4Digits }} will be charged the sum of {{ paygDisplayFee }}.</p>
        </template>

        <template #footer>
          <div class="mb-4 mt-6 px-6 flex">
            <div class="ml-auto flex">
              <GPButton @click.native="paygConfirmationModal = false" color="gray" class="mr-4">
                Close
              </GPButton>
              <GPButton @click.native="save(true)" color="pink">
                Proceed
              </GPButton>
            </div>
          </div>
        </template>
      </ModalWrapper>



      <ModelFormWrapper
              ref="chooseArtistsValidationModel"
              :is-open="validationModal"
              @submitForm="addTiers"
      >
          <template #header>
              <h3 class="text-xl font-semibold text-red-600">All artists require a fee to be set</h3>
              <p>Please add a fee for the selected artist types:</p>
          </template>

          <template #body>
              <div
                  v-if="tiersFormValues.length"
                    class="form"
              >
                  <div
                      v-for="(error, index) in tiersFormValues"
                      :key="error.id"
                      class="grid col-span-full grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                          <div class="mt-1 input-group" placeholder="0.00">
                              <div class="input-content">
                                  <label class="block text-sm font-medium text-gray-700"> {{ error.name }} </label>
                                  <div class="mt-1 relative rounded-md shadow-sm">
                                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                          <span class="text-gray-500 sm:text-sm bg-gray-100 rounded-md py-1 px-2"> £
                                          </span>
                                      </div>
                                      <input
                                          v-model="tiersFormValues[index].fee"
                                          type="number"
                                          step="0.01"
                                          placeholder="0.00"
                                          class="w-full pl-16"
                                          required
                                      >
                                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"><!----></div>
                                  </div><div class="input-error"></div></div></div>
                      </div>
                  </div>
              </div>
          </template>

          <template #footer>
              <div class="mb-4 mt-6 px-6 flex">
                  <div class="ml-auto flex">
                      <GPButton @click.native="closeValidationModal" color="gray" class="mr-4">
                          Close
                      </GPButton>
                      <GPButton
                          :loading="savingFeeButtonLoading"
                          color="pink"
                          class="mr-4">
                          Add
                      </GPButton>
                  </div>
              </div>
          </template>
      </ModelFormWrapper>



	</GigPageWrapper>
</template>

<script>
import GPButton from '../../components/ui/buttons/Button.vue'
import ModalWrapper from '../../components/ui/modal/ModalWrapper.vue'
import ModelFormWrapper from '../../components/ui/modal/ModalFormWrapper.vue'
import SearchByTag from '../../components/gigs/SearchByTag.vue'
import SearchByFave from '../../components/gigs/SearchByFave.vue'
import {DateTime} from 'luxon';
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import Pristine from '../../utils/pristine.js'
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import '../../utils/pristine.js'
import { apiComputed, apiMethods } from '@/state/helpers'; 
import NotificationSmall from '../../components/notifications/small.vue';
import InlineNotification from '../../components/notifications/inline.vue';
import formatDate from '../../utils/format-date.js';
import NProgress from 'nprogress';
import {TAG_TYPES} from '../../state/models/tagType.js';
import { ArrowCircleLeftIcon } from "@vue-hero-icons/outline";

import {
  TToggle,
  TRichSelect
} from 'vue-tailwind/dist/components'
import client from '../../utils/client'


    export default
    {
        name: "ChooseArtists",
        components:{
            GPButton,
            GigPageWrapper, 
            SearchByFave, 
            SearchByTag, 
            TToggle,  
            NotificationSmall, 
            InlineNotification,
            ArrowCircleLeftIcon,
            ModalWrapper,
            ModelFormWrapper,
            NoDataMsgWrapper,
            TRichSelect
        },
        data(){
            return {
                stagedArtists: [],
                is_fetching_faves: false,
                is_fetching_directory: false,
                isLoaded: false,
                item:{},
                form: {},
                faveslist: null,
                addedArtists: [],
                selectedFave: null,
                selectedArtist: null, 
                fastBookedArtist: null,
                tiersWithGenres: null,
                covers: [],
                genres: [],
                moreInfo: [],
                search_tiers: [],
                search_genres: [],
                search_covers: [],
                search_moreInfo: [],
                use_faves: true,
                use_search: false,
                use_hard: false,
                directory: [],
                isLoading:false,
                saveType: null,
                paymentModalOpen: false,
                paymentModalMessage: null,
                paymentModalPAYGButtonLoading: false,
                confirmationModalOpen: false,
                paygConfirmationModal: false,
                paygDisplayFee: '',
                validationModal: false,
                savingFeeButtonLoading: false,
                newTiers: [],
                selectedTiers: [],
                tiersFormValues: [],
                prefix: '£',
                stagedArtistsChanged: false,
                itemChanged: false,
                selectedTiersChanged: false,
                selectedChanged: false,
                coversChanged: false,
                genresChanged: false,
                moreInfoChanged: false,
                changesMade: false,
              selectionLimit: 5,
              tagLimitReached: false,
            }
        },
        async created() {

            NProgress.start();

            await this.getTiers().then((resp) => {
                this.tiersWithGenres = resp;
            }); 

              const queryParams = [
                'artistTags',
                'gigTiers',
                'gigSearchTiers',
                'searchTiers',
                'gigTiers',
                'bookings',
                'stagedArtists.artist',
                'stagedArtists.artist.artistTags',
                'venue',
                'venue.user',
                'venue.activeSubscription',
                'venue.activeSubscription.product'
              ]

            this.getGigChosenArtists({id: this.$attrs.id, queryParams: queryParams}).then((resp) => {
              this.item = JSON.parse(JSON.stringify(resp));
              this.$store.dispatch('gig/storeGig', JSON.parse(JSON.stringify(this.item)));
            }).finally(() => {
                this.loadGig();
                this.isLoaded = true;
                NProgress.done();
            });
        },
        watch:{
          selectedTiers() {
            let newTierIds = this.selectedTiers.filter(tierId => {
              return this.item.gig_tiers.map((gigTier) => {
                return gigTier.tier.id
              }).indexOf(tierId) === -1
            })

            newTierIds.forEach(newId => {
              if (this.newTiers.map(item => {
                return item.id
              }).indexOf(newId) === -1) {
                let t = this.tiers.find(item => {
                  return item.id === newId
                })
                this.newTiers.push(
                    { id: t.id, name: t.name, fee: null }
                )
              }
            })

            this.newTiers.forEach(i => {
              if (newTierIds.indexOf(i.id) === -1) {
                const index = this.newTiers.indexOf(i)
                this.newTiers.splice(index, 1)
              }
            })
            if (this.selectedTiersChanged)
              this.changesMade = true

            this.selectedTiersChanged = true
          },
            use_hard($is_checked){
                if ($is_checked){
                    this.use_faves = false;
                    this.use_search = false;
                }
            },
            use_search($is_checked){
                if ($is_checked){
                    this.use_faves = false;
                    this.use_hard = false;
                }
            },
            use_faves($is_checked){
                if ($is_checked){
                    this.use_hard = false;
                    this.use_search = false;
                }
            },
            item: {
                handler() {
                    this.getTiers();

                    this.is_fetching_faves = true;
                    client.get('favourites-lists-without-paused-artists').then((resp) =>{
                        this.faveslist = resp.data.data.filter((f) => { return f.artists.length !== 0});
                    }).finally(() => this.is_fetching_faves = false);
                    this.is_fetching_directory = true;
                    this.getArtistDirectorySimple(this.item.venue.id).then((resp) => {
                        this.directory = resp;
                    }).finally(() => this.is_fetching_directory = false);
                    this.$store.dispatch('products/getProducts')
                    this.$store.dispatch('user/getUsersRoles')

                    if (this.item.currency !== null) {
                        this.prefix = this.item.currency.symbol
                    } else {
                        this.prefix = '£'
                    }

                    if (this.itemChanged) {
                        this.changesMade = true
                    }

                    this.itemChanged = true
                },
                deep: true
            },
        },
        computed:{
            ...apiComputed,
            last4Digits() {
                return this.item.venue.last_4_digits ? this.item.venue.last_4_digits : ''
            },
            feesAdded() {
                return this.newTiers.filter(item => {
                    return item.fee !== null
                })
            },
            feesNotAdded() {
                return this.newTiers.filter(item => {
                    return item.fee === null
                })
            },
            existingTiers() {
                return this.item.gig_tiers.filter(item => {
                    return this.search_tiers.indexOf(item.tier.id) !== -1
                        && this.newTiers.map(tier => { return tier.id }).indexOf(item.tier.id) === -1
                }).map(i => {
                    return { id: i.tier.id, name: i.tier.name, fee: i.requested_fee }
                })
            },
          payloadFees() {
            return this.item.gig_tiers.map(t => {
              return {
                id: t.tier.id,
                name: t.tier.name,
                fee: t.requested_fee
              }
            })
          },
            loadCheck() {
                return this.isLoading;
            },
            submittableTiers() {
              return [].concat(this.existingTiers).concat(this.feesAdded)
            },
            doesGigNeedBooking(){
                return this.$store.getters['gig/doesCurrentGigNeedBooking']
            },
            useFavesGigBtnDisabled(){
                return this.use_faves ? this.stagedArtists.length === 0 || this.isLoading : false;
            },
            useSearchGigBtnDisabled(){
                return this.use_search ? this.search_tiers.length === 0 || this.isLoading : false;
            },
            getOfferGigBtnLabel(){
                return this.use_hard ? "Fast-book this artist" : "Offer gig to artists";
            },
            getOfferGigBtnColor(){
                return this.use_hard ? "pink" : "indigo";
            },
            getGigBookedLabel(){
                return this.use_hard ? "This gig has been booked" : "GigPig is looking for artists";
            },
          remainingGigCount() {
            return this.item.remaining_usage
          },
          isGigCancelled(){
                return this.$store.getters['gig/isCurrentGigCancelled']
            },
          canSelectMultipleArtists(){
                return this.use_hard 
                ?	this.item.max_bookings > 1 && this.$refs.SearchByFave.addedArtists.length < this.item.max_bookings ? true : false 
                : this.use_faves	
            },
          activeSubscription() {
                return this.venue.active_subscription || undefined;
            },
            monthRemaining(){
                return DateTime.fromISO(this.item.start).toFormat('MMMM yyyy')
            },
            postUsageCount() {
                const postUsageCount = this.remainingGigCount - 1

                return postUsageCount > 0 ? postUsageCount : 0;
            },
            products() {
                return this.$store.getters['products/all']
            },
			user(){
				return this.$store.getters['user/userData']
			},
            userPermissions(){
				return this.$store.getters['user/rolesPermissionsSlugs']
			},
            venue() {
                return this.item.venue;
            },
			isVenueOwner(){
				if(!this.venue){
					return false
				}

				return this.venue.user.id === this.user.id;
			},
            hasBillingPermission() {
                return this.userPermissions.includes('billing-invoices') || this.userPermissions.includes('full-access') || this.isVenueOwner
            }
        },
        methods:{
          updateStaged(e) {
            this.stagedArtists = e;
            
            if (this.use_faves && this.stagedArtistsChanged) {
                this.changesMade = true;
            }

            this.stagedArtistsChanged = true;
          },
            tiersChange(e) {

                this.selectedTiers = [e];

                let tiers = [e];
                let genres = [];
                let covers = [];
                let moreInfo = [];
                let toSplice = [];

                // get all the genres associated to the picked tiers

              tiers.forEach((t) => {
                    this.tiersWithGenres.filter((f) => {
                        if (t === f.id) {
                            f.genres.forEach((x) => {
                                genres.push(x)
                            })
                          f.covers.forEach((x) => {
                            covers.push(x)
                          })
                          f.more_info.forEach((x) => {
                            moreInfo.push(x)
                          })
                        }
                    })
                })

                // remove the duplicate objs from the array
                this.genres = genres.filter((value, index) => {
                    const _value = JSON.stringify(value);
                        return index === genres.findIndex(obj => {
                            return JSON.stringify(obj) === _value;
                    });
                })

              this.covers = covers.filter((value, index) => {
                const _value = JSON.stringify(value);
                return index === covers.findIndex(obj => {
                  return JSON.stringify(obj) === _value;
                });
              })

              this.moreInfo = moreInfo.filter((value, index) => {
                const _value = JSON.stringify(value);
                return index === moreInfo.findIndex(obj => {
                  return JSON.stringify(obj) === _value;
                });
              })

                // splice genres that dont belong to the tier
                let genreChoices = Object.values(JSON.parse(JSON.stringify(this.search_genres)));

                genreChoices.forEach((c) => {
                    let target = genres.filter((f) => { return f.id === c ? f : null });

                    if (target.length === 0) {
                        toSplice.push(c);
                    }
                })

                toSplice.forEach((c) => {
                    let index = genreChoices.indexOf(c);
                      if (index !== -1) {
                        genreChoices.splice(index, 1);
                      }
                })

               this.search_genres = Object.assign([], genreChoices);



              let coversChoices = Object.values(JSON.parse(JSON.stringify(this.search_covers)));

              coversChoices.forEach((c) => {
                let target = covers.filter((f) => { return f.id === c ? f : null });

                if (target.length === 0) {
                  toSplice.push(c);
                }
              })

              toSplice.forEach((c) => {
                let index = coversChoices.indexOf(c);
                  if (index !== -1) {
                    coversChoices.splice(index, 1);
                  }
              })

              this.search_covers = Object.assign([], coversChoices);


              let moreInfoChoices = Object.values(JSON.parse(JSON.stringify(this.search_moreInfo)));

              moreInfoChoices.forEach((c) => {
                // if the selected filter isn't in moreInfo, remove it

                // moreInfo is all of the more_info for all of the currently selected tiers
                let target = moreInfo.filter((f) => { return f.id === c ? f : null });

                if (target.length === 0) {
                  toSplice.push(c);
                }
              })

              // remove selected more_info for any tiers that have been removed
              toSplice.forEach((c) => {
                let index = moreInfoChoices.indexOf(c);
                if (index !== -1) {
                  moreInfoChoices.splice(index, 1);
                }
              })

              this.search_moreInfo = Object.assign([], moreInfoChoices);

            },
            coversChange() {
              this.changesMade = true;

              this.coversChanged = true;
            },
            genresChange(selectedGenres) {
              if (selectedGenres.length > this.selectionLimit) {
                this.search_genres = selectedGenres.slice(0, this.selectionLimit);
              }

              this.tagLimitReached = selectedGenres.length === this.selectionLimit

              this.changesMade = true;

              this.genresChanged = true;
            },
            moreInfoChange() {
                if (this.moreInfoChanged)
                    this.changesMade = true;

                    this.genresChanged = true;
            },
            closeValidationModal() {
                this.tiersFormValues = []
                this.validationModal = false;
                this.isLoading = false;
                this.saveType = null;
            },
            onCalendarWrapperLoaded() {
                if(!this.pristine && this.$refs.form){
                    this.pristine = new Pristine(this.$refs.form, this.pristineConfig);
                }
            },
            loadGig() {
                this.onCalendarWrapperLoaded()

                client.get('favourites-lists-without-paused-artists').then((resp) => {
                    this.faveslist = resp.data.data.filter((f) => { return f.artists.length !== 0});
                })

                this.genres = this.artisttags.filter(tag => tag.tag_type == TAG_TYPES.GENRE); 
 
                    this.use_faves = this.item.search_type == 100;
                    this.use_search = this.item.search_type == 200;
                    this.use_hard = this.item.search_type == 300;

                    this.$refs.SearchByFave.setStaged(this.item.staged_artists);

                    this.search_tiers = this.item.search_tiers.map((tier) => {return tier.id });

                    this.search_genres = this.item.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.GENRE).map((tag) => {return tag.id });
                    this.search_covers = this.item.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.COVERS).map((tag) => {return tag.id });
                    this.search_moreInfo = this.item.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.MORE_INFO).map((tag) => {return tag.id });


            },
            formatDate,                
            ...apiMethods,
            showConfirmationModal() {
                if (
                    this.item.status === 'UNPUBLISHED' && 
                    this.activeSubscription &&
                    this.activeSubscription.product &&
                    (this.activeSubscription.product.id == 1 || this.activeSubscription.product.id == 2  || this.activeSubscription.product.id == 10) &&
                    this.activeSubscription.product.limits &&
                    this.activeSubscription.product.limits.gigs !== null &&
                    this.item.remaining_usage !== null
                ) {
                    this.confirmationModalOpen = true

                    return;
                }

                this.save(true)
            },
            save(published = false) {

                this.isLoading = true;

                if (this.feesNotAdded.length) {
                    this.tiersFormValues = JSON.parse(JSON.stringify(this.feesNotAdded));
                    this.validationModal = true;
                    this.isLoading = false;
                    return
                }

                this.confirmationModalOpen = false
                this.paygConfirmationModal = false

                var valid = this.pristine.validate(); 

                if (valid)
                {
                    this.saveType = published ? 'published' : 'unpublished'
                    let fallbackPublished = this.item ? this.item.published : published;
        
                    let genres = [];

                    if (this.search_genres != undefined)
                    {
                      genres = this.search_genres;
                    }

                  let covers = [];

                  if (this.search_covers != undefined)
                  {
                    covers = this.search_covers;
                  }

                  let moreInfo = [];

                  if (this.search_moreInfo != undefined)
                  {
                    moreInfo = this.search_moreInfo;
                  }

                  let tags = [...genres, ...covers, ...moreInfo];

                  let stagedArtists = null;
                    
                    if(this.fastBookedArtist == null) {
                        stagedArtists = this.$refs.SearchByFave.addedArtists.map(addedArtist => ({
                            fee: addedArtist.fee,
                            artist: addedArtist.artist.id,
                        }))
                    } else{
                        stagedArtists = [
                            {
                                artist: this.fastBookedArtist.id,
                                fee: this.fastBookedArtist.fee,
                            }
                        ];
                    }

                    let payload = {
                        id: this.item.id,  
                        published: published ? published : fallbackPublished, // don't change publish value on save
                        name: this.item.name,
                        start: this.item.start,
                        end: this.item.end,
                        paused: this.item.paused,
                        equipment_venue: this.item.equipment_venue,
                        equipment_artist: this.item.equipment_artist,
                        repertoire: this.item.repertoire,
                        dress_code: this.item.dress_code,
                        parking_details: this.item.parking_details,
                        briefs: this.item.briefs,
                        load_in_time: this.item.load_in_time,
                        sound_check_time: this.item.sound_check_time,
                        performance_times: this.item.performance_times,
                        additional_notes: this.item.additional_notes,
                        invoicing: this.item.invoicing,
                        important: this.item.important,
                        payment_terms: this.item.payment_terms,
                        publish_now: published,
                        search_type: this.use_faves ? 100 : this.use_search ? 200 : 300,
                        tiers: [...this.feesAdded, ...this.payloadFees],
                        search_tiers: this.search_tiers,
                        tags: tags,
                        choose_artists: true,
                        staged_artists: stagedArtists,

                    }

                this.updateGig(payload)
                    .then((response) => {
                        if (published)
                        {
                            if (this.use_hard){
                                this.$notify("Artist Booked"); 
                                if (response.max_bookings === response.bookings_count){
                                    this.$router.push('/gigs/' + payload.id + '/bookings'); 
                                } 
                            } else {
                                this.$router.push('/gigs/' + payload.id + '/offers'); 
                                this.$notify("Looking for artists, check back soon.");
                            }
                        } else {
                            if(this.fastBookedArtist != null) 
                            {
                                this.$notify("Artist Booked"); 
                            } else {
                                this.$notify("Gig updated"); 
                            }
                        }
                        this.saveType = null;
                        this.isLoading = false;
                    }).catch(error => {
                        const { response } = error

                        if (response.status === 402) {
                            this.isLoading = false
                            this.paymentModalOpen = true
                            this.paymentModalMessage = response.data.message

                            return
                        }

                        this.$refs.notification.createNotification(error.response.data.message, true);
                        this.saveType = null;
                    })
            } else
            {                
                this.$refs.notification.createNotification("Please check the form for errors", true);
                this.isLoading = false;
            }
        },
        applyFastBook(artist){
          this.fastBookedArtist = artist;
          this.save(true);
        },
        removeArtists(){
            this.$refs.SearchByFave.addedArtists = [];
        },
        goToPAYGCheckout() {
            const { venue, id } = this.item
            this.paymentModalPAYGButtonLoading = true
            const product = this.products.find(product => product.type === 'one_time')

            client
                .post(`/venues/${venue.id}/checkout`, {
                    product: product.id,
                    gig: id,
                })
                .then(response => {
                    const checkoutUrl = response.data.data.checkout_url

                    if (checkoutUrl !== undefined) {
                        window.location.replace(checkoutUrl)
                    }
                })
        },
        goToManageSubscription() {
            const { venue } = this.item

            this.$router.push(`/venues/${venue.id}/manage-subscription`)
        },
        addTiers() {
            this.tiersFormValues.forEach(item => {
                this.newTiers.find(val => {
                    return val.id === item.id
                }).fee = item.fee
            })

            this.closeValidationModal()
        },
        closePaymentModal() {
            this.paymentModalOpen = false
            this.paymentModalMessage = null
        },
        closeConfirmationModal() {
            this.confirmationModalOpen = false
        }
    },
}
</script>