<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-[#0D1C43] border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                   
                    <div v-show="step == 1" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase" id="modal-title">Your Top up options</h3>
                                <p v-show="this.isVenueTrial && this.isVenueTrial.venue_name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ this.isVenueTrial.venue_name }}</p> 
                            </div>

                            <div class="mt-6">
                                <div class="grid grid-cols-2">
                                    <div>
                                        <p class="text-lg mt-2 text-gray-100">Credits to top-up</p> 
                                        <p class="uppercase text-xs mt-1 text-gray-100 font-medium">1 booking = 1 credit</p> 
                                    </div>
                                    <div class="flex items-center justify-center mt-2">
                                        <div class="w-1/2 py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700" data-hs-input-number="">
                                            <div class="flex justify-between items-center gap-x-5">
                                                <div class="grow">
                                                    <input 
                                                        v-model="cred_qty"
                                                        class="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0" 
                                                        style="appearance: textfield; -moz-appearance: textfield; -webkit-appearance: textfield; position: relative;"
                                                        type="number" 
                                                        aria-roledescription="Number field" 
                                                        value="1" 
                                                        data-hs-input-number-input=""
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid grid-cols-4 mt-12">
                                    <div class="space-y-8 col-span-3">
                                        <p class="text-md mt-2 text-gray-200 font-light">Credits</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">+VAT @ 20%</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Amount to Pay</p> 
                                    </div>
                                    <div class="space-y-8 place-items-end">
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ creditsTotal }}</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ vatTotal }}</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ totalFinal }}</p> 
                                    </div>
                                </div>
                            </div>

                            <div class="w-full pr-10 mt-10">
                                <div @click="addBillingAddress()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-xl px-6 text-white">
                                    <span class="font-semibold w-11/12">Add billing address</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                            </div>

                            <div class="space-y-2 mt-10">
                                <p class="text-xs text-gray-200">Want to be billed monthly and book months in advance?</p>
                                <p @click="openSubscriptionsModal()" class="underline text-xs text-gray-200 font-bold cursor-pointer">View our subscription options</p>
                            </div>

                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="h-auto w-full">
                            <h3 class="block font-bold text-gp_pink-default-400 uppercase mb-12" id="modal-title">FREQUENTLY ASKED QUESTIONS</h3> 
                            <div v-for="(info, index) in faq" 
                                :key="'info' + index" 
                                >
                                <div class="mt-4">
                                    <div class="cursor-pointer flex items-center space-x-3">
                                        <ArrowCircleRightIcon @click="tableIndex = (tableIndex === index ? null : index)" v-if="tableIndex !== index" stroke="white" class="w-5 h-5"/>
                                        <ArrowCircleDownIcon v-if="tableIndex === index" stroke="white" class="w-5 h-5"/>
                                        <span @click="tableIndex = (tableIndex === index ? null : index)" class="text-gray-100 uppercase font-bold text-sm">{{ info.question }}</span>
                                    </div>
                                    <div v-if="tableIndex === index" class="mt-2 bg-none">
                                        <span class="text-gray-300 text-xs">{{ info.answer }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div v-show="step == 2" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase" id="modal-title">BILLING ADDRESS</h3>
                                <p v-show="this.isVenueTrial && this.isVenueTrial.venue_name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ this.isVenueTrial.venue_name }}</p> 
                            </div>

                            <div class="mt-6">
                                <div class="flex items-center justify- space-x-3 pt-8 text-gray-300 text-sm font-semibold">
                                    <span>Same as venue address</span>
                                    <t-toggle @click="same_as_address = !same_as_address;"/>
                                </div>
                                
                                <div v-show="!same_as_address">
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address line one</span>
                                        <input type="text" 
                                                v-model="billing_address.address_line_1"
                                                placeholder="Address line one"
                                                id="billing_address_line_1" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address line two (Optional)</span>
                                        <input type="text" 
                                                v-model="billing_address.address_line_2"
                                                placeholder="Address line two"
                                                id="billing_address_line_2" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address city</span>
                                        <input type="text" 
                                                v-model="billing_address.city"
                                                placeholder="Address city"
                                                id="billing_address_town" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm">
                                        <span class="text-gray-100 text-sm font-light">Billing address postcode</span>
                                        <input type="text" 
                                                v-model="billing_address.post_code"
                                                placeholder="Address postcode"
                                                id="billing_address_post_code" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address country</span>
                                        <input type="text" 
                                                v-model="billing_address.country"
                                                placeholder="Address country"
                                                id="billing_address_county" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                                                required 
                                        />
                                    </div>
                                </div>
                            </div>

                                

                            <div class="w-full pr-10 mt-10">
                                <div @click="addCardDetails()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-xl px-6 text-white">
                                    <span class="font-semibold w-11/12">Add card details</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                            </div>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="h-auto w-full">
                            <div class="flex justify-between mb-12 pr-6">
                                <span class="block font-medium text-gray-100">Amount to pay today</span>
                                <span class="block font-light text-gray-300">£{{ totalFinal }} inc. VAT</span>
                            </div>
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase mb-12" id="modal-title">FREQUENTLY ASKED QUESTIONS</h3> 
                                <div v-for="(info, index) in faq" 
                                    :key="'info' + index" 
                                    >
                                    <div class="mt-4">
                                        <div class="cursor-pointer flex items-center space-x-3">
                                            <ArrowCircleRightIcon @click="tableIndex = (tableIndex === index ? null : index)" v-if="tableIndex !== index" stroke="white" class="w-5 h-5"/>
                                            <ArrowCircleDownIcon v-if="tableIndex === index" stroke="white" class="w-5 h-5"/>
                                            <span @click="tableIndex = (tableIndex === index ? null : index)" class="text-gray-100 uppercase font-bold text-sm">{{ info.question }}</span>
                                        </div>
                                        <div v-if="tableIndex === index" class="mt-2 bg-none">
                                            <span class="text-gray-300 text-xs">{{ info.answer }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 3" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase" id="modal-title">Save a card</h3>
                                <p v-show="this.isVenueTrial && this.isVenueTrial.venue_name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ this.isVenueTrial.venue_name }}</p>  
                            </div>

                            <div class="mt-6">
                                <div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Card nickname</span>
                                        <input type="text" 
                                                v-model="billing_address.card_nickname"
                                                placeholder="e.g. Head office card"
                                                id="billing_address_line_1" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <p class="text-xs text-gray-200 mt-1 w-64">Naming your card will help distinguish different cards used by your business.</p>
                                </div>
                                <div class="mt-6">
                                    <span class="text-gray-100 text-sm font-light">Recipient email for this transaction receipt</span>
                                    <input type="text" 
                                                v-model="billing_address.recipient_email"
                                                placeholder="accounts@myvenue.com"
                                                id="billing_address_line_1" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5 mt-2" 
                                                required 
                                        />
                                </div>
                                <!-- ADD RYFT SDK HERE INSTEAD? -->

                                <div class="grid grid-cols-5 mt-3">
                                    <div class="col-span-1 mt-4">
                                        <t-toggle @click="confirm_charge = !confirm_charge;"/>
                                    </div>
                                    <div class="col-span-4">
                                        <span class="text-gray-200 text-xs text-justify font-light">I confirm my card will be charged by GigPig Ltd for the purpose of token purchases by those with access to my venue.</span>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <span class="text-gray-200 text-xs text-justify font-light">
                                        Your card details will be securly saved for future use by for your venues booking team, so they don’t need access to your card beyond this point.
                                    </span>
                                </div>
                            </div>
                                

                            <div class="w-full pr-10 mt-10">
                                <div @click="addCardDetailsFinal()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-xl px-6 text-white">
                                    <span class="font-semibold w-11/12">Add card details</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                            </div>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="h-auto w-full">
                            <div class="flex justify-between mb-12 pr-6">
                                <span class="block font-medium text-gray-100">Amount to pay today</span>
                                <span class="block font-light text-gray-300">£{{ totalFinal }} inc. VAT</span>
                            </div>
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase mb-12" id="modal-title">FREQUENTLY ASKED QUESTIONS</h3> 
                                <div v-for="(info, index) in faq" 
                                    :key="'info' + index" 
                                    >
                                    <div class="mt-4">
                                        <div class="cursor-pointer flex items-center space-x-3">
                                            <ArrowCircleRightIcon @click="tableIndex = (tableIndex === index ? null : index)" v-if="tableIndex !== index" stroke="white" class="w-5 h-5"/>
                                            <ArrowCircleDownIcon v-if="tableIndex === index" stroke="white" class="w-5 h-5"/>
                                            <span @click="tableIndex = (tableIndex === index ? null : index)" class="text-gray-100 uppercase font-bold text-sm">{{ info.question }}</span>
                                        </div>
                                        <div v-if="tableIndex === index" class="mt-2 bg-none">
                                            <span class="text-gray-300 text-xs">{{ info.answer }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 4" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase" id="modal-title">Confirm Purchase</h3>
                                <p v-show="this.isVenueTrial && this.isVenueTrial.venue_name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ this.isVenueTrial.venue_name }}</p>  
                            </div>

                            <div class="mt-6">
                                
                                <div class="space-y-3">
                                    <p class="block font-bold text-gray-100">Using X card</p>
                                    <p class="block font-bold text-gray-100">Purchasing {{ cred_qty }} credit<span v-show="cred_qty > 1">s</span></p>
                                </div>

                                <div class="grid grid-cols-4 mt-8">
                                    <div class="space-y-8 col-span-3">
                                        <p class="text-md mt-2 text-gray-200 font-light">Credits</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">+VAT @ 20%</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Amount to Pay</p> 
                                    </div>
                                    <div class="space-y-8 place-items-end">
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ creditsTotal }}</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ vatTotal }}</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ totalFinal }}</p> 
                                    </div>
                                </div>
                            </div>

                            <div class="w-full pr-10 mt-10">
                                <div @click="payNow()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-xl px-6 text-white">
                                    <span class="font-semibold w-11/12">Pay now</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                            </div>

                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="h-auto w-full">
                            <h3 class="block font-bold text-gp_pink-default-400 uppercase mb-12" id="modal-title">FREQUENTLY ASKED QUESTIONS</h3> 
                            <div v-for="(info, index) in faq" 
                                :key="'info' + index" 
                                >
                                <div class="mt-4">
                                    <div class="cursor-pointer flex items-center space-x-3">
                                        <ArrowCircleRightIcon @click="tableIndex = (tableIndex === index ? null : index)" v-if="tableIndex !== index" stroke="white" class="w-5 h-5"/>
                                        <ArrowCircleDownIcon v-if="tableIndex === index" stroke="white" class="w-5 h-5"/>
                                        <span @click="tableIndex = (tableIndex === index ? null : index)" class="text-gray-100 uppercase font-bold text-sm">{{ info.question }}</span>
                                    </div>
                                    <div v-if="tableIndex === index" class="mt-2 bg-none">
                                        <span class="text-gray-300 text-xs">{{ info.answer }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 5" class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-400 uppercase" id="modal-title">Purchase Complete</h3>
                                <p v-show="this.isVenueTrial && this.isVenueTrial.venue_name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ this.isVenueTrial.venue_name }}</p>  
                            </div>

                            <div class="mt-6">
                                
                                <div class="space-y-3">
                                    <p class="text-gray-100 text-sm">Payment reference 9234819</p>
                                    <p class="text-gray-100 text-sm">A receipt invoice has been sent to accounts@myvenue.com</p>
                                </div>
                            </div>

                            <div class="w-full pr-10 mt-24">
                                <div @click="returnToPlatform()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-xl px-6 text-white">
                                    <span class="font-semibold w-11/12">Return to platform</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                            </div>

                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="h-auto w-full">
                            <h3 class="block font-bold text-gp_pink-default-400 uppercase mb-12" id="modal-title">FREQUENTLY ASKED QUESTIONS</h3> 
                            <div v-for="(info, index) in faq" 
                                :key="'info' + index" 
                                >
                                <div class="mt-4">
                                    <div class="cursor-pointer flex items-center space-x-3">
                                        <ArrowCircleRightIcon @click="tableIndex = (tableIndex === index ? null : index)" v-if="tableIndex !== index" stroke="white" class="w-5 h-5"/>
                                        <ArrowCircleDownIcon v-if="tableIndex === index" stroke="white" class="w-5 h-5"/>
                                        <span @click="tableIndex = (tableIndex === index ? null : index)" class="text-gray-100 uppercase font-bold text-sm">{{ info.question }}</span>
                                    </div>
                                    <div v-if="tableIndex === index" class="mt-2 bg-none">
                                        <span class="text-gray-300 text-xs">{{ info.answer }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
        <SubscriptionUpgrade ref="subupgrademodal" />
    </div>
</template>

<script>
import { ArrowNarrowRightIcon, ArrowCircleRightIcon, ArrowCircleDownIcon } from "@vue-hero-icons/outline"
import { TToggle } from 'vue-tailwind/dist/components'
import SubscriptionUpgrade from '../../../../components/modal/venues/subscriptions/SubscriptionUpgrade.vue'

export default
{
    name: "TopUpOptions", 
    data() {
        return {
            isVenueTrial: {},
            modal_open: false,
            step: 1,
            tableIndex: null,
            cred_qty: 1,
            billing_address: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                country: '',
                post_code: '',
                company_name: '',
                card_nickname: '',
                recipient_email: '',
            },
            confirm_charge: false,
            same_as_address: false,
            faq: [
                {
                    question:'Lorem ipsum sample text 1',
                    answer:'Lorem ipsum sample text goess here, lorem ipsum sample text goess here'
                },
                {
                    question: 'Lorem ipsum sample text 2',
                    answer: 'Lorem ipsum sample text goess here, lorem ipsum sample text goess here'
                },
                {
                    question: 'Lorem ipsum sample text 3',
                    answer: 'Lorem ipsum sample text goess here, lorem ipsum sample text goess here'
                },
                {
                    question: 'Lorem ipsum sample text 4',
                    answer: 'Lorem ipsum sample text goess here, lorem ipsum sample text goess here'
                } 
            ]
        }
    },
    components: {
        ArrowNarrowRightIcon, ArrowCircleRightIcon, ArrowCircleDownIcon,
        TToggle,
        SubscriptionUpgrade
    },
    computed: {
        creditsTotal() {
            return this.cred_qty * 10
        },
        vatTotal() {
            return (this.cred_qty * 10) * 0.2
        },
        totalFinal() {
            return (this.cred_qty * 10) + ((this.cred_qty * 10) * 0.2)
        }
    },
    mounted() {
		this.isVenueTrial = JSON.parse(localStorage.getItem('venue_trial'));
	},
    methods: {
        toggleModal() {
            this.modal_open = !this.modal_open;
        },
        openSubscriptionsModal() {
            this.$refs.subupgrademodal.toggleModal();
        },
        addBillingAddress() {

            if (!this.cred_qty || this.cred_qty < 1) {
                this.$notify("Please input a credit amount.");
                return;
            }

            this.step = 2;
        },
        addCardDetails() {

            if (!this.same_as_address && (!this.billing_address.address_line_1  || !this.billing_address.city || !this.billing_address.country || 
                !this.billing_address.post_code)) 
            {
                this.$notify("Please provide all requested details.");
                return;
            }

            this.step = 3;

        },
        addCardDetailsFinal() {
            this.step = 4;
        },
        payNow() {
            this.step = 5;
        },
        returnToPlatform() {

        },
    }
}

</script>